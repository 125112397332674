// Fonts
@import url("https://fonts.googleapis.com/css?family=Nunito");

// Required
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

// Icons
@import "../../node_modules/bootstrap-icons/font/bootstrap-icons.scss";

// Bootstrap customization
@import "variables";
@import "customize";

// Bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap.scss";

//
@import "./common";
@import "./developer";
