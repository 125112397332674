/*
 * custom color
 */
// default
$system-theme-color: #22b7f7;
$system-yellow-color: #ede617;
// hover
$system-theme-color-hover: #208ebd;
$system-yellow-color-hover: #ded716;

/*
 * button
 */
$btn-font-weight: bold;

/*
 * typography
 */
$font-size-base: 1rem;
$line-height-base: 1.6;

/*
 * nav pills
 */
$nav-link-font-weight: bold;
$nav-link-color: $system-theme-color;
$nav-link-hover-color: $system-theme-color-hover;
$nav-pills-link-active-bg: $system-theme-color;
