$laravel-color: #f05340;

.devtools__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  border-bottom: solid 2px $laravel-color;
  background: #eee;
  opacity: 0.75;
  list-style: none;
  margin: 0;
  padding: 0 1rem;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  gap: 3;
  li {
    margin: 0;
    padding: 0;

    a {
      color: #333;
      border-left: solid 1px #aaa;
      display: block;
      text-decoration: none;
      padding: 0 0.5rem;
      &:hover {
        background: #ccc;
      }
    }
  }
}
