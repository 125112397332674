body {
  height: 100%;
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-feature-settings: "palt";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*
 * additional styles
 */
// background
.bg- {
  &system {
    background-color: $system-theme-color;
  }
  &yellow {
    background-color: $system-yellow-color;
  }
}
// button
.btn- {
  &system {
    background-color: $system-theme-color;
    color: #fff;
    &:hover {
      background-color: $system-theme-color-hover;
      color: #fff;
    }
  }
  &yellow {
    background-color: $system-yellow-color;
    color: #000;
    &:hover {
      background-color: $system-yellow-color;
      color: #000;
    }
  }
}

/*
 * splash
 */
.splash__ {
  &wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
  }
  &logo {
    width: 100%;
    max-width: 320px;
  }
}

/*
 * page title
 */
.page-title {
  background: $system-theme-color;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  p {
    padding: 0.25rem 0.5rem;
    margin: 0;
  }
}

$nested-cell-border-style: dashed 1px #000;
$cell-border-style-standard: solid 2px #000;

.schedule__calendar {
  &--nested-cell {
    border: $cell-border-style-standard;
    &--date {
      border-bottom: $nested-cell-border-style;
      border-right: $nested-cell-border-style;
    }
    &--title {
      border-bottom: $nested-cell-border-style;
    }
    &--area {
      border-bottom: $nested-cell-border-style;
      border-right: $nested-cell-border-style;
    }
    &--customer {
      border-bottom: $nested-cell-border-style;
      border-right: $nested-cell-border-style;
    }
    &--customer-name {
      border-bottom: $nested-cell-border-style;
    }
    &--pic {
      border-right: $nested-cell-border-style;
    }
  }
  &--holidays {
    &--box {
      border-bottom: $cell-border-style-standard;
      border-right: $cell-border-style-standard;
    }
  }
}

/*
 * Schedule event color
 */
.event {
  &-construction {
    background-color: #ffc107;
  }
  &-survey {
    background-color: #28a745;
  }
  &-icon {
    width: 1rem;
    height: 1rem;
    display: inline-block;
    border-radius: 50%;
    position: relative;
    top: 0.15rem;
    animation: pulse 1s infinite;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
